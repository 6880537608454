import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n';

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [languageIndex, setLanguageIndex] = useState(0);
  const [fade, setFade] = useState('fadeIn');

  const languages = useMemo(() => ['en', 'pt', 'es', 'de', 'fr'], []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (languageIndex + 1) % languages.length;
      i18n.changeLanguage(languages[nextIndex]);
      setLanguageIndex(nextIndex);
      setFade(fade === 'fadeIn' ? 'fadeOut' : 'fadeIn');
    }, 3000);

    return () => clearInterval(intervalId);
  }, [languageIndex, fade, languages, i18n]);

  const fadeClass = fade === 'fadeIn' ? 'fade-in' : 'fade-out';

  return (
    <div className="flex flex-col h-screen">
      <div className="p-4 flex flex-col flex-justify-center items-center w-full">
        <img src="/construction-logo.svg" alt="Logo em Construção" className="w-24 h-24 mb-2" />
        <h2 className="text-lg font-semibold">2Vision</h2>
      </div>
      <div className={`flex-grow flex items-center justify-center ${fadeClass}`}>
        <div className="text-center">
          <h1 className="text-2xl md:text-5xl font-bold text-gray-800 mb-4">{t('title')}</h1>
          <p className="text-base md:text-xl text-gray-600">{t('subtitle')}</p>
        </div>
      </div>
    </div>
  );
};
export default App;
