import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      title: 'Under Construction',
      subtitle: 'Exciting updates coming soon, stay tuned!'
    }
  },
  pt: {
    translation: {
      title: 'Em Construção',
      subtitle: 'Novidades em breve, aguarde!'
    }
  },
  es: {
    translation: {
      title: 'En Construcción',
      subtitle: '¡Pronto grandes novedades, mantente al tanto!'
    }
  },
  de: {
    translation: {
      title: 'Im Aufbau',
      subtitle: 'Neue Highlights bald verfügbar, bleiben Sie dran!'
    }
  },
  fr: {
    translation: {
      title: 'En Construction',
      subtitle: `Nouveautés à venir, restez à l'écoute !`
    }
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
